import React from 'react'
import { graphql } from 'gatsby'
import GraphQLErrorList from '../../components/graphql-error-list'
import SEO from '../../components/seo'
import Layout from '../../containers/layout'
import Container from '../../components/container'
import CardGrid from '../../components/hub/cardGrid/cardGrid'
import SubscribeBar from '../../components/subscribeBar/subscribeBar'

import * as styles from '../../components/globals-v2.module.css'

export const query = graphql`
  query WrittenHubQuery {
    page: sanityPage(title: { eq: "Content Hub" }) {
        title
        metaTitle
        metaDescription
        metaKeywords
        metaImage {
          asset {
            _id
            url
          }
        }
    }

    written: allSanityWritten(sort: {order: DESC, fields: publishedAt}) {
        nodes {
            _type
            tags {
                title
                slug {
                    current
                }
            }
            title
            blurb
            slug {
                current
            }
            featureImage {
                asset {
                    url
                    _id
                }
            }
        }
    }
  }
`

const WrittenHub = props => {
  const { data, errors } = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const page = data.page
  const writtenPosts = data.written.nodes

  if (!page) {
    throw new Error(
      'Missing "Written Hub" page data. Open the studio at http://localhost:3333 and add "Written Hub" page data and restart the development server.'
    )
  }

  return (
    <Layout hideContactBar hideAnnouncements logoOverrideUrl='/content-hub/' darkMode version='2'>
      <SEO title={page.metaTitle || page.title} description={page.metaDescription} keywords={page.metaKeywords.split(',')} imageFB={page.metaImage} />

      <div className={styles.backgroundControl}>
        <Container size='large' class={styles.sectionPad}>
          <section className='writtenPosts'>
            <h6>Written</h6>
            <CardGrid data={writtenPosts} />
          </section>
        </Container>
      </div>

      <div className={styles.backgroundControlAlt}>
        <Container size='large'>
          <SubscribeBar />
        </Container>
      </div>

    </Layout>
  )
}

export default WrittenHub
